<template>
  <v-col cols="12" :xl="isMatomoEnabled ? '6' : '12'" class="align-stretch d-flex responsive-card">
    <v-card
      width="100%"
      elevation="4"
      class="analytics-card analytics-card--system"
    >
      <template v-if="!loading">
        <v-card-text>
          <v-tabs v-model="activeTab" color="info">
            <v-tab class="py-2" v-ripple="false">
              <div class="d-flex align-center">
                <v-icon
                  size="32"
                  disabled
                  :color="activeTab === 0 ? 'info' : ''"
                  class="mr-4"
                >
                  $bandwidth
                </v-icon>
                <div class="d-flex flex-column">
                  <p
                    class="
                      p-4
                      mb-0
                      text-left
                      heading--text
                      font-weight-600
                      analytics-card__title
                    "
                    :class="activeTab !== 0 ? 'gray--text text--darken-1' : ''"
                  >
                    {{ $t('heading.instance.siteDetails.analytics.visitors.bandwidth') }}
                  </p>
                  <p class="p-4 mb-0 text-left gray--text text--darken-1">
                    <span class="current-data heading--text font-weight-600">
                      {{
                        bandwidthData.current ? bandwidthData.current : "0 MB"
                      }}
                    </span>
                  </p>
                </div>
              </div>
            </v-tab>

            <!-- <v-tab class="py-2" v-ripple="false">
            <div class="d-flex align-center">
              <v-icon
                size="32"
                :color="activeTab === 1 ? 'info' : ''"
                class="mr-4"
              >
                $storage
              </v-icon>
              <div class="d-flex flex-column">
                <p
                  class="
                    p-4
                    mb-0
                    text-left
                    heading--text
                    font-weight-600
                    analytics-card__title
                  "
                  :class="activeTab !== 1 ? 'gray--text text--darken-1' : ''"
                >
                  Storage
                </p>
                <p class="p-4 mb-0 text-left gray--text text--darken-1">
                  <span class="p-1 heading--text font-weight-600">{{
                    storageData.current
                  }}</span>
                  / {{ storageData.max }}
                </p>
              </div>
            </div>
          </v-tab>
          <v-tab class="py-2" v-ripple="false">
            <div class="d-flex align-center">
              <v-icon
                size="32"
                :color="activeTab === 2 ? 'info' : ''"
                class="mr-4"
              >
                $cpu
              </v-icon>
              <div class="d-flex flex-column">
                <p
                  class="
                    p-4
                    mb-0
                    text-left
                    heading--text
                    font-weight-600
                    analytics-card__title
                  "
                  :class="activeTab !== 2 ? 'gray--text text--darken-1' : ''"
                >
                  CPU Usage
                </p>
                <p class="p-4 mb-0 text-left">
                  <span class="p-1 heading--text font-weight-600">{{
                    cpuData.current
                  }}</span>
                </p>
              </div>
            </div>
          </v-tab> -->
          </v-tabs>
          <v-tabs-items v-model="activeTab" class="">
            <v-tab-item>
              <system-chart
                :style="options"
                v-if="!loading"
                :chartdata="bandwidthData"
              ></system-chart>
            </v-tab-item>
            <!-- <v-tab-item>
            <system-chart
              :style="options"
              v-if="!loading"
              :chartdata="storageData"
            ></system-chart>
          </v-tab-item>
          <v-tab-item>
            <cpu-chart :style="options" v-if="!loading" :chartdata="cpuData">
            </cpu-chart>
          </v-tab-item> -->
          </v-tabs-items>
        </v-card-text>
      </template>
      <template v-else>
        <chart-card-skeleton />
      </template>
    </v-card>
  </v-col>
</template>

<script>
// import CpuChart from "../charts/CpuChart.vue";
import SystemChart from "../charts/SystemChart.vue";
import ChartCardSkeleton from "./ChartCardSkeleton.vue";

export default {
  components: {
    SystemChart,
    ChartCardSkeleton,
    // CpuChart,
  },
  data: () => ({
    activeTab: 0,
    options: {
      position: "relative",
      height: "220px",
    },
  }),

  props: {
    loading: Boolean,
    fetching: Boolean,
    cpuData: {
      type: Object,
      default: () => ({
        current: 0,
      }),
    },
    bandwidthData: {
      type: Object,
      default: () => ({
        current: 0,
        max: 0,
      }),
    },
    storageData: {
      type: Object,
      default: () => ({
        current: 0,
        max: 0,
      }),
    },
  },
  computed: {
    isMatomoEnabled() {
      return this.$store.state.home.app.matomo_enabled;
    },
  },
};
</script>

<style lang="scss" scoped>
.current-data {
  font-size: $font-size-xlg;
  line-height: $line-height-xlg;
}
</style>